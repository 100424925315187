<template>
  <div>
    <q-img
      :src="localData.logo"
      :ratio="1.5"
      contain
      width="calc(100% - 200px)"
      class="q-my-md q-mx-auto block"
    />

    <h3 class="text-subtitle1 text-center text-weight-medium q-mt-none q-mb-xl">
      {{ localData.title }}
    </h3>

    <q-form @submit.prevent="onSubmitForm">
      <div v-for="field in localData.fields" :key="field.name" class="q-mb-md">
        <q-select
          v-if="field.type === 'select'"
          v-model="field.value"
          :label="field.title"
          :options="field.options"
          :rules="[fieldRules.required]"
          outlined
          emit-value
          map-options
          option-label="title"
          option-value="value"
        />

        <div v-else :hidden="field.type === 'hidden'">
          <q-input
            v-model="field.value"
            :label="field.title"
            :rules="[fieldRules.required]"
            outlined
          />
        </div>
      </div>

      <q-btn
        :loading="loading"
        unelevated
        padding="sm"
        type="submit"
        size="md"
        color="primary"
        class="full-width"
      >
        Create
      </q-btn>
    </q-form>
  </div>
</template>

<script>
import { cloneStructured } from '@/shared/utils';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    const localData = cloneStructured(this.data);

    localData.fields.forEach((field) => {
      field.value = field.value ?? '';
    });

    return {
      localData,
    };
  },
  computed: {
    fieldRules() {
      return {
        required(value) {
          return (
            value?.toString().trim().length > 0 || 'The field must not be empty'
          );
        },
      };
    },
  },
  methods: {
    onSubmitForm() {
      this.$emit('submit', this.localData);
    },
  },
};
</script>
